.dashboard-widget {
    position: relative;
}
.dashboard-widget .widget-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 50px;
    opacity: 0.15;
}
.dashboard-widget.MuiPaper-root {
    overflow: visible;
}
.dashboard-widget .MuiToggleButton-root {
    font-size: 10px;
    font-weight: bold;
}