.event-desc-item {
    display: inline-block;
    vertical-align: top;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
    padding: 2px 2px 2px 5px;
    margin: 3px 5px 3px 0;
}
.event-desc-item > strong {
    display: inline-block;
    margin-right: 5px;
}
.event-commit-id {
    font-size: 10px;
    font-family: monospace;
}