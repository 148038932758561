.admin-notes-list .MuiListItem-root {
    position: relative;
}
.admin-note-actions {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 5px 3px 0;
    font-size: 10px;
    background: rgba(255, 255, 255, 0.9);
    display: none;
}
.admin-notes-list .MuiListItem-root:hover .admin-note-actions {
    display: block;
}
.admin-notes-list .MuiListItem-root.pinned,
.admin-notes-list .MuiListItem-root.pinned .admin-note-actions {
    background: #ffffc0;
}