
.MuiTableRow-root.error {
    background: #fff3f3;
    color: maroon;
}
.MuiTableRow-root.error * {
    color: maroon;
}
.dateRangePicker-wrapper .materialui-daterange-picker-makeStyles-dateRangePickerContainer-1 {
    position: absolute;
}
.MuiTableCell-root .MuiAvatar-root {
    float: left;
    vertical-align: top;
    width: 26px;
    height: 26px;
    margin-top: -3px;
    margin-right: 10px;
    color: #fff !important;
}
.MuiDialogActions-root {
    margin-top: 30px;
    border-top: 1px solid #e3e3e3;
}
.chipSharpCornersParent .MuiChip-root {
    border-radius: 3px;
}
.MuiTableCell-root .locked-label {
    font-size: 12px;
    margin-top: 6px;
    color: #989898;
}
.MuiTableCell-root .locked-label svg {
    font-size: 14px;
    color: #6a6a6a;
}